<template>
  <div>
    <h1
      :class="$vuetify.breakpoint.mdAndUp ? 'fz-28' : 'fz-22 py-0 line-h-26'"
      class="dark-text mb-4 ml-4 mt-6 mt-md-0 font-weight-medium"
    >Import Contacts</h1>

    <v-card
      :style="isDesktop ? '' : 'box-shadow:none !important'"
      class="transparent pa-0"
      elevation="0"
    >
      <v-row class="w-100 pa-0 ma-0">
        <v-col
          cols="12"
          md="12"
          class="pa-0"
        >
          <v-card
            :style="isDesktop ? '' : 'box-shadow:none !important'"
            class="pa-4 fz-14 dark-text w-100"
            max-width="100%"
          >
            <p :class="$vuetify.breakpoint.mdAndUp ? 'fz-14' : 'fz-16 font-weight-medium grey--text'">Import from your gmail contacts</p>
            <v-btn
              :class="isDesktop ? 'w-max' : 'w-100'"
              class="grey-button mt-1 px-3 d-flex align-center justify-center"
              height="48px"
            >
              <!-- <v-icon color="#919EAB" class="mr-2">mail</v-icon> -->
              <v-img
                contain
                width="27"
                height="20"
                max-width="min-content"
                class="mx-auto"
                :src="require('../assets/icon-gmail.svg')" />
              <span class="ml-3">
                Import from Gmail
              </span>
            </v-btn>
          </v-card>
        </v-col>

<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--          class="pt-0 d-flex justify-end"-->
<!--        >-->
<!--          <v-btn-->
<!--            to="/imported-contacts"-->
<!--            class="violet-button"-->
<!--          >-->
<!--            Import-->
<!--          </v-btn>-->
<!--        </v-col>-->
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ImportContactsComponent',
  computed: {
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style scoped>
.grey-button ::v-deep .v-btn__content {
  flex: 0;
}
</style>
