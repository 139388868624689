<template>
  <div>
    <h1
      :class="isDesktop ? 'font-weight-semi-bold fz-28 mb-4' : 'fz-22 font-weight-bold pt-3 px-4'"
      class="dark-text"
    >Assign Number</h1>
    <v-card class="pa-md-2" :style="!isDesktop ? 'box-shadow:none' : ''">
      <v-card-text class="pa-3">
        <v-form v-model="valid">
          <v-row class="red--text" v-if="error">{{error}}</v-row>
          <v-row v-for="(user, index) in form.users" :key="index">
            <v-col
              cols="7"
              md="6"
              class="pb-0"
            >
              <label class="fz-14 mb-1 d-block">Phone number</label>
              <v-select
                v-model="user.selectPhone"
                :items="phoneList"
                :disabled="adding && index !== form.users.length - 1"
                item-text="label"
                item-value="value"
                item-disabled="disabled"
                solo
                outlined
                menu-props="offsetY"
                hide-details
                label="Phone number"
                class="pa-0"
                dense
                @focus="numberFocus(index)"
                @change="checkNumberChanges(index)"
              >
                <!-- @change="selectNumber(index, user.selectPhone)" -->
                <template v-slot:append>
                  <v-icon size="15px">unfold_more</v-icon>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="4"
              md="5"
              class="pb-0"
            >
              <label class="fz-14 mb-1 d-block">Extension</label>
              <v-text-field
                v-model="user.extension"
                :rules="extensionRules"
                :disabled="adding && index !== form.users.length - 1"
                label="Extension"
                type="text"
                solo
                outlined
                hide-details
                dense
                maxlength="4"
                @focus="checkChanges(index)"
                @blur="resetTemp()"
                @input="checkExtensions($event, index)"
                @keypress="IsNumber($event)"
                @keyup.enter.prevent="addExtension(index)"
              >
                <template v-slot:prepend-inner>
                  ×
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="1"
              md="1"
              class="mt-6 pb-0 px-0"
            >
              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="error !== '' && (index !== form.users.length - 1 || user.extension !== '')"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="(user.editing && error === '') || allowSave(user) ? addExtension(index, true) : remove(index)"
                  >
                    <v-icon size="20px">
                      {{ (user.editing && error === '') || allowSave(user) ? 'save' : 'close'}}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ (user.editing && error === '') || allowSave(user) ? 'Save' : 'Remove'}}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-btn
                :disabled="error !== '' || adding"
                text
                class="transparent py-0 text-capitalize px-2"
                height="28px"
                @click="add"
              >
                <v-icon
                  size="20px"
                  color="#919EAB"
                  class="mr-1"
                >add
                </v-icon>
                Add more
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    <vue-snotify/>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'assign-number-component',
  props: {
    uuid: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    changed: false,
    valid: false,
    phoneList: [],
    error: '',
    userName: '',
    userUuid: '',
    form: {
      users: [
        {
          selectPhone: '',
          extension: '',
          extensionUuid: '',
          enable: false,
          editing: false
        }
      ]
    },
    disabledNumbers: [],
    extensionRules: [
      v => !!v || 'Extenstion is required'
    ],
    addingExtension: false,
    tempExtension: {
      index: -1,
      extension: '',
      number: ''
    },
    adding: false
  }),
  watch: {
    form: {
      deep: true,
      handler () {
        this.changed = true
        this.$emit('changed', true)
      }
    },
    error (val) {
      this.$emit('error', val)
    }
  },
  created () {
    this.getUserData()
    this.getNumbers()
  },
  computed: {
    isDesktop () { return this.$vuetify.breakpoint.mdAndUp }
  },
  methods: {
    ...mapActions([
      'didNumbersList',
      'createExtension',
      'getExtensions',
      'deleteExtension',
      'editExtension'
    ]),
    async remove (index) {
      try {
        const uuid = this.form.users[index].extensionUuid
        if (uuid) {
          const { data } = await this.deleteExtension(uuid)
          this.$snotify.notifications = []
          this.$snotify.success('Extensions has been removed')
          this.$emit('onSuccess')
        }
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      } finally {
        if (index === this.form.users.length - 1) {
          this.adding = false
          this.error = ''
        }
        this.form.users.splice(index, 1)
        this.resetTemp()
      }
    },
    add () {
      if (this.tempExtension.index >= 0) this.addExtension(this.tempExtension.index)
      const newUser = {
        selectPhone: '',
        extension: '',
        extensionUuid: '',
        enable: false,
        editing: false
      }
      this.adding = true
      this.form.users.push(newUser)
      this.resetTemp()
    },
    async getUserData () {
      if (this.$route.params.type === 'memberDetails' || this.uuid) {
        const uuid = this.$route.params.id ? this.$route.params.id : this.uuid
        await this.$store
          .dispatch('getMember', uuid)
          .then((res) => {
            const item = res.data.member
            this.userName = item.user_first_name + ' ' + item.user_last_name
            this.$emit('userName', this.userName)
            this.userUuid = uuid
          }).catch(err => {
            throw new Error(err)
          })
      } else if (this.$route.params.type === 'contactDetails') {
        await this.$store
          .dispatch('getContact', this.$route.params.id)
          .then((res) => {
            const item = res.data.contact
            this.userName = item.firstName + ' ' + item.lastName
            this.$emit('userName', this.userName)
          }).catch(err => {
            throw new Error(err)
          })
      }
    },
    async getNumbers () {
      try {
        const queries = {
          order_dir: 'DESC',
          order_by: 'createdOn'
        }

        const { data } = await this.didNumbersList(queries)

        this.phoneList = data.didList.map(item => ({
          value: item.uuid,
          label: item.didNumber,
          disabled: false
        }))
        this.loadExtensions()
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadExtensions () {
      const userUuid = this.$route.params.id ? this.$route.params.id : this.uuid
      if (!userUuid) return
      const { data } = await this.getExtensions({ userUuid })
      if (!data.extensions || !data.extensions.length) return
      this.form.users = data.extensions.map(item => ({
        selectPhone: this.phoneList.find(e => e.label === item.didNumber)?.value,
        extension: `${item.extensionNumber}`,
        extensionUuid: item.uuid,
        enable: item.enable,
        editing: false
      }))
    },
    async addExtension (index, close = false) {
      try {
        if (this.addingExtension) return
        this.addingExtension = true
        const extension = this.form.users[index]
        if (this.error || !extension.extension || !extension.selectPhone || (!extension.editing && !this.allowSave(extension))) return
        if (index === this.form.users.length - 1) this.adding = false
        if (!extension.extensionUuid) {
          const { data } = await this.createExtension({
            extension: +extension.extension,
            didUuid: extension.selectPhone,
            userUuid: this.userUuid
          })
          if (!data.success) return
          extension.extensionUuid = data.extension.uuid
          this.$snotify.notifications = []
          this.$snotify.success('Extensions has been added')
        } else {
          if (!extension.extensionUuid) return
          console.log(extension, 'extesion')

          const payload = {
            enable: extension.enable,
            extension: +extension.extension,
            didUuid: extension.selectPhone
          }
          const { data } = await this.editExtension({
            uuid: extension.extensionUuid,
            payload
          })
          if (!data.success) return
          this.$snotify.notifications = []
          this.$snotify.success('Extensions has been changed')
        }
        this.tempExtension.index = -1
        this.tempExtension.extension = ''
        this.tempExtension.number = ''
        this.$emit('onSuccess')
        extension.editing = false
        if (close) this.$emit('close')
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      } finally {
        this.addingExtension = false
      }
    },
    /* selectNumber (index, uuid) {
      if (index in this.disabledNumbers) {
        this.disabledNumbers[index] = uuid
        this.setDisabledNumbers()
      } else {
        this.disabledNumbers.push(uuid)
      }
    },
    setDisabledNumbers () {
      this.phoneList = this.phoneList.map(item => {
        return {
          ...item,
          disabled: this.disabledNumbers.includes(item.value)
        }
      })
    }, */
    checkExtensions (e, index) {
      this.error = ''
      this.form.users.forEach((element, idx) => {
        if (index !== idx && (element.extension === e || !e)) {
          this.error = e ? 'Same extension was specified.' : 'Extensions must not be empty.'
        }
      })
      this.checkChanges(index)
    },
    checkChanges (index) {
      const ext = this.form.users[index]
      if ((this.tempExtension.extension || !ext.extensionUuid) &&
          this.tempExtension.number &&
          this.tempExtension.index === index &&
          (this.tempExtension.extension !== ext.extension || this.tempExtension.number !== ext.selectPhone)) {
        this.form.users[index].editing = true
        this.changed = true
        return
      }
      if (this.tempExtension.index < 0) {
        this.tempExtension.index = index
        this.tempExtension.extension = ext.extension
        if (ext.selectPhone) this.tempExtension.number = ext.selectPhone
      } else if (this.tempExtension.index !== index) {
        const tIdx = this.tempExtension.index
        if (this.tempExtension.extension) this.form.users[tIdx].extension = this.tempExtension.extension
        if (this.tempExtension.number) this.form.users[tIdx].selectPhone = this.tempExtension.number
        this.form.users[tIdx].editing = false
        this.tempExtension.index = index
        this.tempExtension.extension = ext.extension
        this.form.users[index].editing = false
        this.changed = false
      } else {
        if (!this.tempExtension.extension) this.tempExtension.extension = ext.extension
        if (!this.tempExtension.number && ext.selectPhone) this.tempExtension.number = ext.selectPhone
        this.form.users[index].editing = false
        this.changed = false
      }
    },
    checkNumberChanges (index) {
      const ext = this.form.users[index]
      if (this.tempExtension.index < 0 || this.tempExtension.index !== index) {
        if (this.tempExtension.index !== index) {
          const tIdx = this.tempExtension.index
          if (this.tempExtension.extension) this.form.users[tIdx].extension = this.tempExtension.extension
          if (this.tempExtension.number) this.form.users[tIdx].selectPhone = this.tempExtension.number
          this.form.users[tIdx].editing = false
        }
        this.tempExtension.index = index
        this.tempExtension.number = ext.selectPhone
        ext.editing = this.changed = ext.extension !== ''
      } else {
        ext.editing = this.changed = this.tempExtension.number !== ext.selectPhone && ext.extension !== ''
      }
    },
    numberFocus (index) {
      if (this.tempExtension.index < 0) {
        const ext = this.form.users[index]
        this.tempExtension.index = index
        this.tempExtension.extension = ext.extension
        this.tempExtension.number = ext.selectPhone
      }
    },
    allowSave (ext) {
      return ext.selectPhone && ext.extension !== '' && !ext.extensionUuid
    },
    resetTemp () {
      this.tempExtension = {
        index: -1,
        extension: '',
        number: ''
      }
    }
  }
}
</script>
