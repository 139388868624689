<template>
  <div class="pt-4">
    <h1 class="fz-28 dark-text font-weight-semi-bold mb-1 d-none d-md-block">
      {{ $route.path === '/contacts' ? 'Contacts' : 'All Members' }}
    </h1>
    <h1 class="fz-25 dark-text font-weight-semi-bold mb-1 px-7 d-md-none">
      {{ $route.path === '/contacts' ? 'Contacts' : 'All Members' }}
    </h1>

    <div class="light-text fz-14 font-weight-regular d-flex flex-wrap mb-4 px-6 px-md-0">
      <v-btn
        text
        class="text-none d-flex align-center px-2"
        height="36px"
        :to="$route.path === '/members' ? '/new-member' : '/new-contact'"
      >
        <v-icon
          class="mr-1"
          size="20px"
          color="#919EAB"
        >
          {{ $route.path === '/contacts' ? 'person_add' : 'add' }}
        </v-icon>

        <span :class="{ 'text-capitalize': !isDesktop }" class="light-text">
          {{ $route.path === '/contacts' ? 'New Contact' : 'Add Member' }}
        </span>
      </v-btn>

      <v-btn
        v-if="$route.path === '/members'"
        text
        class="text-none d-flex align-center px-2"
        height="36px"
        :to="{ name: 'InviteMembers' }"
      >
        <!-- <v-icon
          v-if="$route.path === '/contacts'"
          class="mr-n2 mirror"
          color="#919EAB"
          style="transform: rotate(90deg)"
          size="20px"
          dense
        >
          arrow_right_alt
        </v-icon> -->
        <v-img
          v-if="$route.path === '/contacts'"
          :src="require('../assets/import-contacts.svg')"
          contain
          width="32px"
          height="30px"
          max-width="min-content"
          class="mr-1"
        />
        <v-icon
          v-else
          class="mr-1"
          color="#919EAB"
          size="20px"
        >
          {{ $route.path === '/contacts' ? 'groups' : 'group_add' }}
        </v-icon>

        <span class="light-text">
            {{ $route.path === '/contacts' ? 'Import Contacts' : 'Invite Members' }}
        </span>
      </v-btn>
      <!-- <invite-members v-if="showInviteMembers" @success="showInviteMembers = false" /> -->

<!--      <template v-if="($store.state.auth.currentUser.role === 'account_admin' || $store.state.auth.currentUser.role === 'account_user') && $route.path === '/contacts'">-->
<!--        <SelectComponent select-width="130px" :value="contacts.contactTypes"/>-->
<!--      </template>-->

<!--      <template v-else>-->
<!--        <MoreActionComponent/>-->
<!--      </template>-->
    </div>

<!--    desktop-->
    <v-card class="fz-14 mb-5 d-none d-md-block" :loading="loading">
      <v-list class="py-0">
        <template v-for="(item, itemIndex) in contacts.items">
          <v-divider
            v-if="itemIndex"
            :key="itemIndex + 'divider'"
          ></v-divider>
          <v-list-item
            :key="itemIndex"
            :class="{expanded:item.expand}"
            class="px-5"
            @click.stop.prevent="$route.path === '/contacts' ? getSelectedId(item.contact_uuid, true) : openExpand(item.user_uuid)"
          >
            <v-tooltip v-if="item.unreadMessage || item.missedCall" top content-class="v-tooltip--top">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on" small color="#5C6AC4" style="font-size: 6px" class="ml-n3 mr-1">circle</v-icon>
              </template>
              <span>
                <span>{{ item.unreadMessage ? 'Unread messages' : '' }}</span>
                <span>{{ item.missedCall ? 'Missed calls' : '' }}</span>
              </span>
            </v-tooltip>
            <v-list-item-avatar width="50px" height="50px" class="my-4 mr-3">
              <v-img
                :alt="`${fullName(item.firstName, item.lastName)} avatar`"
                :src="loading ? '' : (item.image_link || require('../assets/default-user-image.jpg'))"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-semi-bold">
                <span
                  class="py-4 pr-4"
                  @click.stop.prevent="$route.path === '/contacts' ? getSelectedId(item.contact_uuid, true) : getSelectedId(item.user_uuid, true)"
                >{{ fullName($route.path === '/contacts' ? item.firstName : item.user_first_name, $route.path === '/contacts' ? item.lastName : item.user_last_name) }}</span>
                <v-icon small v-if="item.missedCall" color="#919EAB">person</v-icon>
              </v-list-item-title>

              <v-list-item-subtitle class="fz-14">
                <span v-if="$route.path === '/members'" class="mr-5">{{item.role === 'system_admin' ? 'Company admin' : 'Company user'}}</span>
                <span>{{ $route.path === '/members' ? item.userPhoneNumber : item.phoneNumber }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="flex-row align-center v-list-item__action--hover">
              <!-- <v-tooltip v-if="$route.path === '/members'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    @click.stop.prevent="assignNumberDialog(item)"
                  >
                    <v-icon color="#919EAB"
                    >{{
                     $route.path === '/contacts' ? 'history' : 'phone'
                      }}
                    </v-icon>
                    <v-icon
                      v-if="$store.state.auth.currentUser && $route.path !== '/contacts'"
                      class="ml-n5 mt-n3"
                      x-small
                      color="#919EAB"
                    >person
                    </v-icon>
                  </v-btn>
                </template>

                <span>Assign Number</span>
              </v-tooltip> -->
              <v-tooltip v-if="$route.path === '/members'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    @click.stop.prevent="$route.path === '/contacts' ? $router.push('/chat') : openAssignExtension(item)"
                  >
                    <v-icon color="#919EAB"
                    >{{
                     $route.path === '/contacts' ? 'history' : 'extension'
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $route.path === '/contacts' ? 'History' : 'Assign Extension' }}</span>
              </v-tooltip>

              <v-tooltip v-if="$route.path === '/contacts' || didNumberCount > 0" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :style="{ opacity: $route.path === '/members' && (!item.extensions || !item.extensions.length) ? '0.4' : '1' }"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="$route.path === '/contacts' ? callClicked(item) : assignUser(item)"
                  ><!-- assignUser(assignUserPopUp = !assignUserPopUp) -->
                    <v-icon :color="isFreeAccount ? '#919EAB80' : '#919EAB'">{{
                      $route.path === '/contacts' ? 'phone' : 'link'
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $route.path === '/contacts' ? 'Make a call' : 'Show Extension' }}</span>
              </v-tooltip>

              <v-tooltip top content-class="v-tooltip--top">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="$route.path === '/members'"
                    icon
                    class="mx-1 "
                    height="36px"
                    v-on="on"
                    @click.stop.prevent="openChangeDialog(item)"
                  >
                    <v-icon color="#919EAB">{{!item.user_active ? 'check': 'block'}}</v-icon>
                  </v-btn>
<!--                   <v-btn
                    v-else
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    :to="$store.state.auth.currentUser.role !== 'account_admin' ? '' : {name: 'Chat', params: {isCalling: true, isVideo: true}}"
                  >
                    <v-icon color="#919EAB">videocam</v-icon>
                  </v-btn> -->
                </template>

                <span v-if="$route.path === '/members'">{{ !item.user_active ? 'Activate' : 'Deactivate' }}</span>
                <!-- <span v-else>Make a video call</span> -->
              </v-tooltip>

              <v-tooltip v-if="$route.path === '/contacts'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="getSelectedId(item.contact_uuid, false)"
                  >
                    <v-icon color="#919EAB">{{
                      $store.state.auth.currentUser.role ? 'person' : ''
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $store.state.auth.currentUser.role ? 'Contact Details' : '' }}</span>
              </v-tooltip>
              <v-tooltip v-if="$route.path === '/members'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="openDeleteDialog(item.user_uuid)"
                  >
                    <v-icon color="#919EAB">{{
                      $store.state.auth.currentUser.role === 'account_admin' ? 'delete' : ''
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $store.state.auth.currentUser.role === 'account_admin' ? 'Delete' : '' }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action class="flex-row align-center v-list-item__action--normal">
              <v-tooltip v-if="item.extensions && item.extensions.length" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="mx-1"
                    height="36px"
                  >
                    <v-icon color="#919EAB">{{item.expand ? 'expand_less' : 'expand_more'}}</v-icon>
                  </v-btn>
                </template>
                <span>Expand</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-expand-transition :key="itemIndex + 'extand'" class="pa-0 expand-items">
            <div v-if="item.expand">
              <v-divider />
              <div v-if="item.extensions.length" class="px-0">
                <v-row align="center" class="pa-5">
                  <v-col cols="3" class="pa-1 ml-4">Phone Number</v-col>
                  <v-col cols="4" class="pa-1">Extension</v-col>
                </v-row>
                <template v-for="extension in item.extensions">
                  <v-divider :key="extension.id + 'ext'" />
                  <v-row :key="extension.id + 'row'" align="center" class="pa-5">
                    <v-col cols="3" class="pa-1 ml-4">{{extension.didNumber}}</v-col>
                    <v-col cols="4" class="pa-1">
                      {{extension.extensionNumber}}
                    </v-col>
                  </v-row>
                </template>
              </div>
              <div v-else class="px-7 py-3">Member has no extension number</div>
            </div>
          </v-expand-transition>
        </template>
        <v-divider/>
        <div v-if="contacts.total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider
                v-if="index"
                :key="index + 'v-divider--page'"
                vertical
                class="pa-0"
              />
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_back
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon size="20" color="#687885">
                arrow_forward
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>
    </v-card>
    <v-card v-if="!contacts.items.length && !loading" class="fz-16 mb-5 d-none d-md-flex pa-5 align-center justify-center font-weight-bold">
      <span>{{ $route.path === '/contacts' ? 'No contact found' : 'No member found' }}</span>
    </v-card>
<!--    mobile-->
    <v-card class="fz-14 mb-5 d-md-none" :loading="loading">
      <v-list class="py-0">
        <template v-for="(item, itemIndex) in contacts.items">
          <v-divider
            v-if="itemIndex"
            :key="itemIndex + 'divider'"
          ></v-divider>
          <v-list-item
            :key="itemIndex"
            class="px-5"
            style="height:70px"
            @click.stop.prevent="$route.path === '/contacts' ? getSelectedId(item.contact_uuid, true) : openExpand(item.user_uuid, false)"
          >
            <v-list-item-avatar width="38px" height="38px" min-width="38px" class="my-1 mr-3">
              <v-img
                :alt="`${fullName(item.firstName, item.lastName)} avatar`"
                :src="loading ? '' : (item.image_link || require('../assets/default-user-image.jpg'))"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="py-2">
              <v-list-item-title class="fz-14 font-weight-semi-bold d-flex" style="max-width:fit-content">
                <span class="pt-1 pb-2 pr-4" @click.stop.prevent=" getSelectedId($route.path === '/contacts' ? item.contact_uuid : item.user_uuid, true)">
                  {{ fullName($route.path === '/contacts' ? item.firstName : item.user_first_name, $route.path === '/contacts' ? item.lastName : item.user_last_name) }}
                </span>
                <v-icon small v-if="item.missedCall" color="#919EAB">person</v-icon>
              </v-list-item-title>

              <v-list-item-subtitle class="fz-14 d-flex">
                <span v-if="$route.path === '/members'" class="mr-5">{{item.role === 'system_admin' ? 'Company admin' : 'Company user'}}</span>
                <span>{{ $route.path === '/members' ? item.userPhoneNumber : item.phoneNumber }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action
              :class="$route.path === '/contacts' ? ' v-list-item__action--normal' : 'v-list-item__action--hover'"
              class="flex-row align-center"
              style="top: 0; right: 0; bottom: 0"
            >
              <v-tooltip top content-class="v-tooltip--top"
                         v-if="$route.path === '/members' && $store.state.auth.currentUser"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="openAssignNumberPopUp(item.user_uuid)"
                  >
                    <v-icon color="#919EAB"
                    >{{
                      $route.path === '/contacts' ? 'history' : 'extension'
                      }}
                    </v-icon>
                    <!-- <v-icon
                      class="ml-n5 mt-n3"
                      x-small
                      color="#919EAB"
                      v-if="$store.state.auth.currentUser && $route.path !== '/contacts'"
                    >person
                    </v-icon> -->
                  </v-btn>
                </template>

                <span>{{ $route.path === '/contacts' ? 'History' : 'Assign Extension' }}</span>
              </v-tooltip>

              <v-tooltip v-if="$route.path === '/contacts' || didNumberCount > 0" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :style="{ opacity: $route.path === '/members' && (!item.extensions || !item.extensions.length) ? '0.4' : '1' }"
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="$route.path === '/contacts' ? callClicked(item) : assignUser(item)"
                  >
                    <v-icon :color="isFreeAccount ? '#919EAB80' : '#919EAB'">{{
                      $route.path === '/contacts' ? 'phone' : 'link'
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $route.path === '/contacts' ? 'Make a call' : 'Show Extension' }}</span>
              </v-tooltip>

              <v-tooltip v-if="$route.path === '/members'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="openChangeDialog(item)"
                  >
                    <v-icon color="#919EAB">{{!item.user_active ? 'check': 'block'}}</v-icon>
                  </v-btn>
                </template>

                <span v-if="$route.path === '/members'">{{ !item.user_active ? 'Activate' : 'Deactivate' }}</span>
              </v-tooltip>

              <v-tooltip v-if="$route.path === '/contacts'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    @click.stop.prevent="getSelectedId(item.contact_uuid, false)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">{{
                      $route.path === '/contacts' ? 'person' : ''
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $route.path === '/contacts' ? 'Contact Details' : '' }}</span>
              </v-tooltip>
              <v-tooltip v-if="$route.path === '/members'" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    class="mx-1"
                    height="36px"
                    @click.stop.prevent="openDeleteDialog(item.user_uuid)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#919EAB">{{
                      $store.state.auth.currentUser.role === 'account_admin' ? 'delete' : ''
                      }}
                    </v-icon>
                  </v-btn>
                </template>

                <span>{{ $store.state.auth.currentUser.role === 'account_admin' ? 'Delete' : '' }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-action v-if="$route.path === '/members'" class="flex-row align-center v-list-item__action--normal">
              <v-tooltip v-if="item.extensions && item.extensions.length" top content-class="v-tooltip--top">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on.stop="on"
                    icon
                    class="mx-1"
                    height="36px"
                  >
                    <v-icon color="#919EAB">{{item.expand ? 'expand_less' : 'expand_more'}}</v-icon>
                  </v-btn>
                </template>
                <span>Expand</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-expand-transition :key="itemIndex + 'extand'" class="pa-0">
            <div v-if="item.expand">
              <v-divider />
              <div v-if="$route.path === '/members' && didNumberCount > 0" class="px-4">
                <v-row align="center" class="pa-5">
                  <v-col cols="4" class="pa-0">Phone Number</v-col>
                  <v-col cols="4" class="pa-0">Extension</v-col>
                </v-row>
                <template v-for="extension in item.extensions">
                  <v-divider :key="extension.id + 'ext'" />
                  <v-row :key="extension.id + 'row'" align="center" class="pa-5">
                    <v-col cols="4" class="pa-0">{{extension.didNumber}}</v-col>
                    <v-col cols="4" class="pa-0">
                      ×{{extension.extensionNumber}}
                    </v-col>
                  </v-row>
                </template>
              </div>
              <div v-else class="px-7 py-3">Member has no extension number</div>
            </div>
          </v-expand-transition>
        </template>
        <v-divider/>
        <div v-if="contacts.total > itemsPerPage" class="d-flex justify-space-between">
          <div class="ma-5 br-3 grey-button d-flex">
            <template v-for="index in countPage">
              <v-divider :key="index + 'v-divider--page'" v-if="index" vertical class="pa-0"/>
              <v-btn
                :class="{'grey-button--selected': index === page}"
                text
                tile
                min-width="56"
                class=""
                :key="index"
                @click="pagination(index)"
              >{{ index }}
              </v-btn>
            </template>
          </div>

          <div class="ma-5 br-3 grey-button d-flex">
            <v-btn
              text
              tile
              class="px-0"
              min-width="36"
              width="36px"
              @click="previousPage(page)"
            >
              <v-icon small>
                navigate_before
              </v-icon>
            </v-btn>
            <v-divider vertical/>
            <v-btn
              text
              tile
              min-width="36"
              class="light-text px-0"
              width="36px"
              @click="nextPage(page)"
            >
              <v-icon small>
                navigate_next
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-list>
    </v-card>
    <v-card
      v-if="!contacts.items.length && !loading"
      class="fz-16 mb-5 pa-5 d-flex d-md-none align-center justify-center font-weight-bold"
    >
      <span>{{ $route.path === '/contacts' ? 'No contact found' : 'No member found' }}</span>
    </v-card>
    <vue-snotify/>
    <v-dialog
        persistent
        v-model="deleteDialog"
        :max-width="isDesktop ? '350' : '260'"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            {{changeDialog ? ( isActive ? 'Deactivate' : 'Activate' ) : 'Delete'}} member
          </v-card-title>

          <v-card-text>
            Are you sure you want to {{changeDialog ? ( isActive ? 'deactivate' : 'activate' ) : 'delete'}} member?
          </v-card-text>

          <v-card-actions class="pb-5 pt-0 justify-md-end">

            <div :style="isDesktop ? '' : 'width: 50%'"
                class="mx-2">
              <v-btn
                color="secondary"
                outlined
                class="w-100 text-capitalize"
                @click="closeDeleteDialog"
              >
                Cancel
              </v-btn>
            </div>

            <div :style="isDesktop ? '' : 'width: 50%'"
                class="mx-2">
              <v-btn
                depressed
                color="primary"
                @click="deleteMember"
                class="w-100 text-capitalize"
              >
                {{ changeDialog ? ( isActive ? 'Deactivate' : 'Activate' ) : 'Delete' }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <div
      v-if="assignNumberPopUp && !isDesktop"
      class="assign-number-container"
      @click="closeAssignedNumberPopUp"
    >
      <div @click.stop>
        <assign-number-component
          class="assign-number"
          :uuid="assignNumberToUser"
          @onSuccess="extensionsChanged = true"
          @close="assignNumberPopUp = false"
        />
      </div>
    </div>
    <div
      v-if="importContactPopUp && !isDesktop"
      class="assign-number-container"
      @click="importContactPopUp = false"
    >
      <div @click.stop>
        <import-contacts-component
          class="assign-number"
        />
      </div>
    </div>
    <assign-number-pop-up
      v-if="isAssignNumberDialog"
      :dialog="isAssignNumberDialog"
      :member="assignNumberToMember"
      @close="closeAssignNumberDialog"
    />
  </div>
</template>

<script>
// import SelectComponent from '@/components/SelectComponent'
import AssignNumberComponent from '@/components/AssignNumberComponent'
import AssignNumberPopUp from '@/components/AssignNumberPopUp'
import ImportContactsComponent from '@/components/ImportContactsComponent'
// import InviteMembers from '@/components/InviteMembers'

import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ContactsOverview',
  components: {
    AssignNumberComponent,
    AssignNumberPopUp,
    ImportContactsComponent
    // InviteMembers
  },
  data: () => ({
    isActive: true,
    loading: false,
    itemsPerPage: 10,
    page: 1,
    contacts: {
      contactTypes: [
        'All contacts',
        'Company staff',
        'Outside contacts'
      ],
      items: [],
      total: 0
    },
    deleteDialog: false,
    changeDialog: false,
    selectedMemberUuid: null,
    assignNumberPopUp: false,
    assignNumberToUser: '',
    extensionsChanged: false,
    didNumberCount: 0,
    isAssignNumberDialog: false,
    importContactPopUp: false,
    dids: [],
    showInviteMembers: false
  }),
  computed: {
    ...mapGetters({
      extensions: 'extensions',
      currentCompany: 'currentCompany'
    }),
    isFreeAccount () { return this.currentCompany?.is_free },
    countPage () {
      return Math.ceil(this.contacts.total / this.itemsPerPage)
    },
    ...mapState('components', [
      'rightPopUpMyContacts'
    ]),
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  created () {
    this.contactList()
    this.membersList()
    this.didCount()
  },
  watch: {
    page () {
      this.contactList()
      this.membersList()
    }
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions([
      'getExtensions',
      'getContactImage',
      'getContacts',
      'getMembers',
      'didNumbersList',
      'disableMember'
    ]),
    assignNumberDialog (member) {
      this.isAssignNumberDialog = true
      this.assignNumberToMember = member
    },
    closeAssignNumberDialog (e) {
      this.isAssignNumberDialog = false
      this.assignNumberToMember = null
      if (!e || !e.success) return
      this.$snotify.notifications = []
      this.$snotify.success('Successful')
    },
    answer (item) {
      console.log(parseInt(item.phoneNumber), '---', item.phoneNumber)
      const callOption = {
        phone: parseInt(item.phoneNumber),
        status: true
      }
      this.$store.dispatch('components/callModalStatus', callOption)
    },
    toChat (userInfo) {
      const callOption = {
        phone: userInfo.phoneNumber,
        status: true
      }
      console.log(userInfo)
      this.$store.dispatch('components/callModalStatus', callOption)
      // // eslint-disable-next-line no-unused-expressions
      // this.$store.state.auth.currentUser.role === 'account_admin' && this.$route.path === '/members' ? this.assignUser(this.assignUserPopUp = !this.assignUserPopUp) : ''
      // // alert(this.$route.path)
      // this.$store.dispatch('components/userInfo', userInfo)
      // router.push(this.$route.path === '/contacts' ? { name: 'Chat' } : '')
    },
    openAssignExtension (item) {
      if (!this.didNumberCount) {
        this.$snotify.error('There is no DID present in your account')
        return
      }
      this.$router.push({ name: 'AssignNumber', params: { id: item.user_uuid, type: 'memberDetails' } })
    },
    async contactList () {
      if (this.$route.path !== '/contacts') return
      this.loading = true
      try {
        const { data } = await this.getContacts({
          skip: (this.page - 1) * this.itemsPerPage,
          take: this.itemsPerPage,
          orderBy: 'contactName',
          orderType: 'ASC'
        })
        this.contacts.items = data.contactList
        this.contacts.total = data.total
        if (!this.contacts.total) this.$router.push('/new-contact')

        /* this.contacts.items.forEach(async item => {
          const image = await this.getContactImage({
            id: item.contact_uuid,
            params: {
              responseType: 'arraybuffer'
            }
          })

          item.imageLink = image
        }) */
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 400)
      }
    },
    async membersList () {
      if (this.$store.state.auth.currentUser.role !== 'account_admin' || this.$route.path !== '/members') return
      this.loading = true
      try {
        await this.getExtensions()
        const { data } = await this.getMembers({
          skip: (this.page - 1) * this.itemsPerPage,
          take: this.itemsPerPage,
          orderType: 'desc',
          orderBy: 'created'
        })
        this.contacts.total = data.total
        this.contacts.items = data.members.map(item => ({
          ...item,
          expand: false,
          extensions: this.extensions.filter(e => e.assignedUserUuid === item.user_uuid)
        }))
        if (!this.contacts.total) this.$router.push('/new-member')
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async didCount () {
      try {
        const { data } = await this.didNumbersList({})
        this.dids = data.didList
        this.didNumberCount = data.didList.length
      } catch (err) {
        throw new Error(err)
      }
    },
    openExpand (uuid, isDesktop = true) {
      if (!this.didNumberCount) return this.$router.push({ name: 'UserProfile', params: { type: this.$route.path === '/contacts' ? 'contactDetails' : 'memberDetails', id: uuid } })
      const item = this.contacts.items.find(e => e.user_uuid === uuid)
      if (!item.extensions.length) {
        if (isDesktop) this.getSelectedId(uuid, true)
        return
      }

      this.contacts.items.forEach(v => {
        if (v.expand && v.user_uuid !== item.user_uuid) {
          v.expand = false
        }
      })

      item.expand = !item.expand
    },
    getSelectedId (id, changeRoute) {
      const show = this.$store.state.user.selectedContactId !== id
      if (this.$route.path !== '/members') {
        this.$store.dispatch('components/ContactDetails', show)
      }
      this.$store.dispatch('setSelectedContactId', show ? id : null)

      if (changeRoute) {
        this.$router.push({ name: 'UserProfile', params: { type: this.$route.path === '/contacts' ? 'contactDetails' : 'memberDetails', id } })
      }
    },
    assignUser (user) {
      const uuid = user.user_uuid
      if (this.$route.path === '/members' && (!user.extensions || !user.extensions.length)) {
        this.$snotify.notifications = []
        this.$snotify.error('No extension attached')
        return
      }
      if (!this.didNumberCount) return
      // this.$store.dispatch('components/RightPopUpMyContacts', payload)
      const item = this.contacts.items.find(e => e.user_uuid === uuid)
      this.contacts.items.forEach(v => {
        if (v.expand && v.user_uuid !== item.user_uuid) {
          v.expand = false
        }
      })
      if (!item) return
      // if (!item.extensions.length) return
      item.expand = !item.expand
    },
    openChangeDialog (item) {
      this.isActive = item.user_active
      this.selectedMemberUuid = item.user_uuid
      this.changeDialog = true
      this.deleteDialog = true
    },
    async changeDisable () {
      try {
        const list = {
          id: this.selectedMemberUuid,
          active: !this.isActive
        }
        const { data } = await this.disableMember(list)
        if (!data) return
        this.membersList()
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        this.closeDeleteDialog()
      } catch (err) {
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    openDeleteDialog (uuid) {
      this.deleteDialog = true
      this.selectedMemberUuid = uuid
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.selectedMemberUuid = null
      setTimeout(() => {
        this.changeDialog = false
      }, 1000)
    },
    async deleteMember () {
      try {
        if (this.changeDialog) {
          this.changeDisable()
          return
        }
        const { status } = await this.$store.dispatch('deleteMember', this.selectedMemberUuid)

        if (status === 200) {
          this.$snotify.notifications = []
          this.$snotify.success('Member deleted!')

          this.membersList()
          this.closeDeleteDialog()
        }
      } catch (e) {
        this.$snotify.error(e)
        throw new Error(e)
      }
    },
    openAssignNumberPopUp (uuid) {
      if (this.$route.path === '/contacts') {
        this.$router.push('/chat')
        return
      }
      if (!this.didNumberCount) {
        this.$snotify.error('There is no DID present in your account')
        return
      }
      this.assignNumberToUser = uuid
      this.assignNumberPopUp = true
    },
    closeAssignedNumberPopUp () {
      this.assignNumberPopUp = false
      this.assignNumberToUser = ''
      if (this.extensionsChanged) {
        this.membersList()
        this.extensionsChanged = false
      }
    },
    redirect () {
      if (this.$route.path === '/members') return this.$router.push('/invite-members')
      if (this.isDesktop) return this.$router.push('/import-contacts')
      this.importContactPopUp = true
    },
    fullName (first, last) {
      return (first != null ? first : '') + ' ' + (last != null ? last : '')
    },
    pagination (index) {
      this.page = index
    },
    previousPage (page) {
      if (page !== 1) {
        this.page = page - 1
      }
    },
    nextPage (page) {
      if (page < this.countPage) {
        this.page = page + 1
      }
    },
    async callClicked (item) {
      if (this.isFreeAccount) {
        if (this.didNumberCount) {
          this.setSubscribeDialog(true)
        } else {
          this.$snotify.error('Please add number to make a call.')
        }
        return
      }
      this.answer(item)
    }
  }
}
</script>

<style scoped>
.expanded {
  background: #F4F6F8;
}
.expand-items{
  font-family: "SF Pro Text", serif !important;
}
.assign-number-container {
  position: fixed;
  z-index: 9999;
  inset: 0;
  background: #E7E7E770;
}
.assign-number {
  position: absolute;
  inset: auto 0 0 0;
  min-height: 221px;
  max-height: 75vh;
  overflow-y: auto;
  background: #fff;
}
</style>
